import './App.css';
import {useEffect, useState} from "react";
import { Pagination, A11y, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import './App.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Timeline from "./Timeline";

function App() {
  const [slideCount, setSlideCount] = useState(3);
  useEffect(() => {
    const onQueryChanges = e => {
      setSlideCount(e.matches ? 1 : 3);
    }
    const query = window.matchMedia('(max-width: 650px)');
    query.addEventListener('change', onQueryChanges);

    onQueryChanges(query);
    return () => {
      query.removeEventListener('change', onQueryChanges);
    }
  }, [setSlideCount]);

  return (
      <Swiper
          modules={[Pagination, A11y, Navigation]}
          navigation={slideCount > 1}
          slidesPerView={slideCount}
          pagination={{clickable: true}}>
        <SwiperSlide>
          <Timeline />
          <header>
            Today
          </header>
          <div>
            <p>
              30+ Years in Business.<br/>
              200+ Employees.<br/>
              6 Locations.<br />
              Infinite Creativity & Passion.<br/>
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <Timeline />
          <header>2019</header>
          <div>
            <p>
              Launch of Facility Operations services, such as construction management in the aerospace and aviation industries.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <Timeline />
          <header>2006</header>
          <div>
            <p>
              Launch of Protective Services division that provides armed security and specialized firefighting in the aerospace and aviation industries.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <Timeline />
          <header>1992</header>
          <div>
            <p>
              Launch of IT division supporting federal and state governmental entities.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <Timeline />
          <header>1989</header>
          <div>
            <p>
              Founded with his retirement account funds and credit cards, Albuquerque born and raised mechanical engineer, Bill Miera, formed Fiore to provide engineering services in the aerospace, aviation and defense industries.
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
  );
}

export default App;
