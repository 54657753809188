import React from 'react';
import './Timeline.css';

function Timeline() {
  return (
    <div className="timeline">
      <div className="timeline-container">
        <div className="dot" />
        <div className="line"/>
        <div className="dot" />
      </div>
    </div>
  );
}

export default Timeline;